<template>
  <v-container class="pa-4 mt-n8">
    <v-form ref="form" @submit.prevent="handleSave">
      <v-container fluid>
        <v-row>
          <v-col>
            <calendesk-information-message>
              {{ $trans("create_user_subscription_info_1") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <calendesk-information-message>
              {{ $trans("create_user_subscription_info_2") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="subscriptionId"
              :item-text="getSubscriptionName"
              :items="searchedSubscriptions || []"
              :label="$trans('subscription')"
              :rules="[rules.required]"
              clearable
              hide-details="auto"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <clients-field
              v-model="user"
              chips
              deletable-chips
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <calendar-input
              v-model="createdAt"
              :rules="[rules.required]"
              :label="$trans('subscription_created_at')"
              :max="$moment().format($helpers.dateFormat)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0 mb-0">
            <v-checkbox
              v-model="wantsCreateEndsDate"
              :disabled="isLoading"
              :label="$trans('wants_create_subscription_end_date_checkbox')"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-if="wantsCreateEndsDate">
          <v-col cols="12" sm="6">
            <calendar-input
              v-model="endsAt"
              :label="$trans('subscription_ends_at')"
              :disabled="isLoading"
              :rules="[rules.required]"
              :min="subscriptionEndDateMin"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 mt-0">
            <v-checkbox
              v-model="createPayment"
              :disabled="isLoading"
              :label="$trans('create_subscription_payment')"
              hide-details
            />
          </v-col>
        </v-row>
        <template v-if="createPayment">
          <v-row v-if="user && subscriptionLoaded">
            <v-col
              v-if="!showFakturowniaWarning && !showCustomerDataInvoiceWarning"
              cols="12"
            >
              <calendesk-information-message>
                {{ $trans("create_payment_invoice_info") }}
              </calendesk-information-message>
            </v-col>
            <v-col v-else-if="showFakturowniaWarning" cols="12">
              <calendesk-information-message
                color="orange"
                additional-class="white--text"
                icon-color="white"
                icon="$alert"
              >
                {{ $trans("invoice_warning_fakturownia_disabled") }}
              </calendesk-information-message>
            </v-col>
            <v-col v-else-if="showCustomerDataInvoiceWarning" cols="12">
              <calendesk-information-message
                color="orange"
                additional-class="white--text"
                icon-color="white"
                icon="$alert"
              >
                {{ $trans("invoice_warning_client_data") }}
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="paymentMethod"
                :disabled="isLoading"
                :items="paymentMethods"
                :label="$trans('payment_method')"
                :rules="[rules.required]"
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="amount"
                :disabled="loadingSubscriptionPrice || isLoading"
                :label="$trans('invoice_total')"
                :loading="loadingSubscriptionPrice"
                :rules="[
                  rules.required,
                  rules.maxNumber,
                  rules.minNumber(amount, 0),
                ]"
                :suffix="$config('currency')"
                hide-details
                outlined
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="transactionId"
                :disabled="isLoading"
                :label="$trans('payment_transaction_transaction_id')"
                :rules="[rules.maxChars(255)]"
                hide-details
                outlined
              />
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {
  maxChars,
  maxNumber,
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  canIssueInvoiceForUser,
  paymentMethodsForSelect,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import { mapActions, mapGetters } from "vuex";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import CalendarInput from "@/lib/calendesk-js-library/components/CalendarInput.vue";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "CreateUserSubscriptionDialog",
  components: {
    ClientsField,
    CalendarInput,
    CalendeskInformationMessage,
  },
  mixins: [planActions],
  data() {
    return {
      user: null,
      subscriptionId: null,
      createPayment: false,
      createdAt: this.$moment().format(this.$helpers.dateFormat),
      endsAt: null,
      wantsCreateEndsDate: false,
      wantsInvoice: false,
      subscriptionLoaded: false,
      endsAtTime: null,
      paymentMethod: this.$helpers.paymentMethods.CASH,
      paymentMethods: paymentMethodsForSelect(),
      isLoading: false,
      transactionId: null,
      loadingSubscriptionPrice: false,
      amount: 0,
      rules: {
        required,
        maxChars,
        maxNumber,
        minNumber,
      },
    };
  },
  computed: {
    ...mapGetters({
      searchedSubscriptions: "subscriptions/getSearchedSubscriptions",
    }),
    subscriptionEndDateMin() {
      if (
        this.createdAt &&
        this.$moment(this.createdAt).isAfter(this.$moment())
      ) {
        return this.createdAt;
      } else {
        return this.$moment().format(this.$helpers.dateFormat);
      }
    },
    showCustomerDataInvoiceWarning() {
      return (
        this.wantsInvoice && (!this.user || !canIssueInvoiceForUser(this.user))
      );
    },
    showFakturowniaWarning() {
      return this.wantsInvoice && !this.isFakturowniaEnabled;
    },
  },
  watch: {
    subscriptionId() {
      this.loadSubscriptionPrice();
    },
    createdAt() {
      this.endsAt = null;
    },
  },
  methods: {
    ...mapActions({
      refreshUserSubscriptionsList:
        "subscriptions/refreshUserSubscriptionsList",
      fetchStats: "dashboard/fetchStats",
      create: "subscriptions/createUserSubscription",
      fetchSubscription: "subscriptions/fetchSubscription",
    }),
    loadSubscriptionPrice() {
      this.loadingSubscriptionPrice = true;
      this.fetchSubscription(this.subscriptionId)
        .then((subscription) => {
          if (subscription.price) {
            this.amount = subscription.price.unit_amount / 100;
            this.wantsInvoice = subscription.wants_invoice;
            this.subscriptionLoaded = true;
          }
        })
        .finally(() => {
          this.loadingSubscriptionPrice = false;
        });
    },
    getSubscriptionName(subscription) {
      return `(${this.$trans("id")}: ${subscription.id}) ${subscription.name}`;
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    async save() {
      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);

      try {
        const data = {
          user_id: this.user.id,
          subscription_id: this.subscriptionId,
          create_payment: this.createPayment,
        };

        if (this.createPayment) {
          data.payment_method = this.paymentMethod;
          data.transaction_id = this.transactionId;
          data.amount = Math.round(this.amount * 100);
        }

        if (this.createdAt) {
          data.created_at = this.createdAt;
        }

        if (this.wantsCreateEndsDate && this.endsAt) {
          data.ends_at = this.endsAt;
        }

        pushEvent("createUserSubscription");
        await this.create(data);
        await this.fetchStats().then(() => {
          this.refreshUserSubscriptionsList();
        });

        this.$emit("close");
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.$emit("ctaButtonLoading", false);
        this.isLoading = false;
      }
    },
  },
};
</script>
