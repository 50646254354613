<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="selected"
    no-filter
    :items="users"
    :loading="isLoading"
    :search-input.sync="search"
    item-value="id"
    hide-no-data
    hide-details="auto"
    :multiple="multiple"
    :dense="dense"
    outlined
    :disabled="disabled"
    clearable
    :label="label ? label : $trans('client')"
    :loading-text="$trans('loading')"
    :no-data-text="$trans('nothing_found_here')"
    :placeholder="$trans('autocomplete_hint')"
    return-object
    :rules="rules"
    :attach="'#' + uniqueId"
    class="cd-fake-outer"
    @blur="handleInputBlur"
  >
    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="data.index < maxItemsInSelectField">
          <template v-if="chips || smallChips">
            <v-chip
              :disabled="disabled"
              :close="deletableChips"
              :small="smallChips"
              @click:close="remove(data.item)"
            >
              <v-auto-complete-chips-tooltip>
                <user-card-details :user="data.item" />
              </v-auto-complete-chips-tooltip>
              <v-avatar left>
                <v-img :src="$helpers.getAvatarSrc(data.item)" />
              </v-avatar>
              <span class="text-truncate">{{
                $helpers.getUserName(data.item)
              }}</span>
            </v-chip>
          </template>
          <template v-else>
            <v-avatar left>
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span class="text-truncate">{{
              $helpers.getUserName(data.item)
            }}</span>
          </template>
        </template>
        <div
          v-if="multiple && data.index === maxItemsInSelectField"
          class="grey--text text-caption"
        >
          (+{{ selected.length - maxItemsInSelectField }} {{ $trans("more") }})
        </div>
      </div>
    </template>

    <template #item="data">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div class="d-flex align-center pointer my-2" v-on="on">
            <v-avatar :size="32" left class="mr-2">
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span class="text-break cd-v-select-line-height">{{
              $helpers.getUserName(data.item)
            }}</span>
          </div>
        </template>
        <user-card-details :user="data.item" />
      </v-tooltip>
    </template>

    <template #append>
      <v-icon>$magnify</v-icon>
    </template>

    <template #append-outer>
      <div :id="uniqueId"></div>
      <div v-if="loggedUserCanAddUsers && !hideActions && !disabled">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div
              class="outlined-icon pt-4 pl-4"
              v-on="on"
              @click="handleSetNewClientModal"
            >
              <v-icon>$plus</v-icon>
            </div>
          </template>
          {{ $trans("add") }}
        </v-tooltip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import planActions from "@/calendesk/mixins/planActions";
import { debounce } from "debounce";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import UserCardDetails from "@/components/UserCardDetails.vue";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import roleActions from "@/calendesk/mixins/roleActions";
import VAutoCompleteChipsTooltip from "@/lib/calendesk-js-library/components/VAutoCompleteChipsTooltip.vue";

export default {
  name: "ClientsField",
  components: { VAutoCompleteChipsTooltip, UserCardDetails },
  mixins: [roleActions, planActions, sharedFieldActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    keepMenuOpenOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,
      selected: this.value,
      users: this.value ? (this.multiple ? this.value : [this.value]) : [],
    };
  },
  computed: {
    ...mapGetters({
      newUser: "user/getNewUser",
    }),
  },
  watch: {
    selected(value) {
      if (!this.keepMenuOpenOnSelect) {
        this.$refs.autocomplete.isFocused = false;
      }
      this.$emit("input", value);
    },
    value(value) {
      this.selected = value;
    },
    newUser(val) {
      if (this.hideActions) {
        return;
      }

      if (val) {
        const cloned = this.$helpers.cloneObject(val);

        if (this.multiple) {
          const alreadySelected = this.selected.some(
            (user) => user.id === val.id
          );

          if (!alreadySelected) {
            this.selected.push(cloned);
          }
        } else {
          this.selected = cloned;
        }

        const alreadyInUsers = this.users.some((user) => user.id === val.id);

        if (!alreadyInUsers) {
          this.users.push(cloned);
        }

        this.$emit("userAdded");
      }
    },
    search(query) {
      const canQuery = query && query.length > 0;

      if (canQuery) {
        this.getQueryResults(query);
      }
    },
  },
  methods: {
    ...mapActions({
      userSearch: "user/search",
      setNewClientModal: "calendar/setNewClientModal",
    }),
    getQueryResults: debounce(function (query) {
      this.fetchData(query);
    }, 300),
    remove(item) {
      if (this.multiple) {
        const filtered = this.selected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );

        this.selected = this.$helpers.cloneObject(filtered);
      } else {
        this.selected = null;
      }
    },
    fetchData(query) {
      if (query && query.length > 0) {
        const params = { query };
        this.isLoading = true;

        this.userSearch(params)
          .then((data) => {
            this.mergeResponseWithSelectedUsers(data);
          })
          .catch((error) => {
            errorNotification(null, error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    mergeResponseWithSelectedUsers(users) {
      let result = users;

      if (this.multiple) {
        result =
          result && result.length > 0
            ? result
            : [
                {
                  header: this.$trans("nothing_found_here"),
                  id: "selected",
                },
              ];

        result = [...result];

        if (this.selected && this.selected.length > 0) {
          result.push({ divider: true });
          result.push(...this.selected);
        }
      } else if (this.selected) {
        result = [this.selected, ...result];
      }

      // Remove duplicates
      this.users = result;
    },
    handleSetNewClientModal() {
      this.setNewClientModal(true);
    },
    handleInputBlur() {
      // Because we load all the services (items) to the input, when leaving the input, we don't want to keep the search value.
      // For some reason, v-autocomplete doesn't reset this value, even though we use .sync option.
      setTimeout(() => {
        if (this.$refs.autocomplete && !this.$refs.autocomplete.isMenuActive) {
          this.search = null;
        }
      }, 500);
    },
  },
};
</script>
